import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import '../styles/global.css';
import Header from './header';
import Footer from './footer';
import BackToTop from './BackToTop';
import { KeyDatesModal } from './KeyDates';
import { useSiteMetadata } from '../hooks/use-site-metadata';

export default function Layout({ children }) {
  const location = useLocation();
  const { siteUrl } = useSiteMetadata();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);

    const currentDomain = window.location.origin;

    if (currentDomain === siteUrl) {
      document.documentElement.lang = 'en';
    }
  }, [siteUrl]);

  useEffect(() => {
    document.body.classList.add('!mt-0');

    const pathname = location.pathname.replace(/\//g, '-').replace(/^-|-$/g, '');
    document.body.id = pathname ? pathname : 'home';

    const observerCallback = (mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach(node => {
            if (node.nodeType === 1) { 
              const elements = node.querySelectorAll('[data-radix-menubar-content]');
              elements.forEach(element => {
                if (element.parentElement) {
                  element.parentElement.classList.add('has-radix-menubar-content');
                }
              });
            }
          });
        }
      }
    };

    const observer = new MutationObserver(observerCallback);

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
      document.body.classList.remove('!mt-0');
      document.body.removeAttribute('id');
    };
  }, [location.pathname]);

  const customPages = ["/"];
  const shouldHaveClasses = customPages.includes(location.pathname);

  return (
    <>
      {/* <Header /> */}
      <main className={`${!shouldHaveClasses ? 'flex flex-col gap-12 py-12 md:py-16' : ''}`}>
        {children}
      </main>
      {/* <Footer />
      {isClient && <BackToTop />}
      {isClient && <KeyDatesModal />} */}
    </>
  );
}
