import React from "react";
import { Link } from "gatsby";

const NavItem = ({ to, children }) => (
  <Link to={to}>
    {children}
  </Link>
);

export default NavItem;
