import useGoogleSheets from 'use-google-sheets';

const APIKey = "AIzaSyD7_vDWpGYiSRBVai_2_Aa1QPmSdqIjrzQ"

const useGoogleSheetData = (sheetId, pageId) => {
  const { data, loading, error } = useGoogleSheets({
    apiKey: APIKey,
    sheetId: sheetId,
    sheetsOptions: [{ id: pageId }],
  });

  return { data, loading, error };
};

export default useGoogleSheetData;
